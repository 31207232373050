const cars = [
  {
    brand: "Seat",
    models: [
      "Alhambra",
      "Altea",
      "Altea XL",
      "Arosa",
      "Cordoba",
      "Cordoba Vario",
      "Exeo",
      "Ibiza",
      "Ibiza ST",
      "Exeo ST",
      "Leon",
      "Leon ST",
      "Inca",
      "Mii",
      "Toledo",
    ],
  },
  {
    brand: "Renault",
    models: [
      "Captur",
      "Clio",
      "Clio Grandtour",
      "Espace",
      "Express",
      "Fluence",
      "Grand Espace",
      "Grand Modus",
      "Grand Scenic",
      "Kadjar",
      "Kangoo",
      "Kangoo Express",
      "Koleos",
      "Laguna",
      "Laguna Grandtour",
      "Latitude",
      "Mascott",
      "Mégane",
      "Mégane CC",
      "Mégane Combi",
      "Mégane Grandtour",
      "Mégane Coupé",
      "Mégane Scénic",
      "Scénic",
      "Talisman",
      "Talisman Grandtour",
      "Thalia",
      "Twingo",
      "Wind",
      "Zoé",
    ],
  },
  {
    brand: "Peugeot",
    models: [
      "1007",
      "107",
      "106",
      "108",
      "2008",
      "205",
      "205 Cabrio",
      "206",
      "206 CC",
      "206 SW",
      "207",
      "207 CC",
      "207 SW",
      "306",
      "307",
      "307 CC",
      "307 SW",
      "308",
      "308 CC",
      "308 SW",
      "309",
      "4007",
      "4008",
      "405",
      "406",
      "407",
      "407 SW",
      "5008",
      "508",
      "508 SW",
      "605",
      "806",
      "607",
      "807",
      "Bipper",
      "RCZ",
    ],
  },
  {
    brand: "Dacia",
    models: [
      "Dokker",
      "Duster",
      "Lodgy",
      "Logan",
      "Logan MCV",
      "Logan Van",
      "Sandero",
      "Solenza",
    ],
  },
  {
    brand: "Citroën",
    models: [
      "Berlingo",
      "C-Crosser",
      "C-Elissée",
      "C-Zero",
      "C1",
      "C2",
      "C3",
      "C3 Picasso",
      "C4",
      "C4 Aircross",
      "C4 Cactus",
      "C4 Coupé",
      "C4 Grand Picasso",
      "C4 Sedan",
      "C5",
      "C5 Break",
      "C5 Tourer",
      "C6",
      "C8",
      "DS3",
      "DS4",
      "DS5",
      "Evasion",
      "Jumper",
      "Jumpy",
      "Saxo",
      "Nemo",
      "Xantia",
      "Xsara",
    ],
  },
  {
    brand: "Opel",
    models: [
      "Agila",
      "Ampera",
      "Antara",
      "Astra",
      "Astra cabrio",
      "Astra caravan",
      "Astra coupé",
      "Calibra",
      "Campo",
      "Cascada",
      "Corsa",
      "Frontera",
      "Insignia",
      "Insignia kombi",
      "Kadett",
      "Meriva",
      "Mokka",
      "Movano",
      "Omega",
      "Signum",
      "Vectra",
      "Vectra Caravan",
      "Vivaro",
      "Vivaro Kombi",
      "Zafira",
    ],
  },
  {
    brand: "Alfa Romeo",
    models: [
      "145",
      "146",
      "147",
      "155",
      "156",
      "156 Sportwagon",
      "159",
      "159 Sportwagon",
      "164",
      "166",
      "4C",
      "Brera",
      "GTV",
      "MiTo",
      "Crosswagon",
      "Spider",
      "GT",
      "Giulietta",
      "Giulia",
    ],
  },
  {
    brand: "Škoda",
    models: [
      "Favorit",
      "Felicia",
      "Citigo",
      "Fabia",
      "Fabia Combi",
      "Fabia Sedan",
      "Felicia Combi",
      "Octavia",
      "Octavia Combi",
      "Roomster",
      "Yeti",
      "Rapid",
      "Rapid Spaceback",
      "Superb",
      "Superb Combi",
    ],
  },
  {
    brand: "Chevrolet",
    models: [
      "Alero",
      "Aveo",
      "Camaro",
      "Captiva",
      "Corvette",
      "Cruze",
      "Cruze SW",
      "Epica",
      "Equinox",
      "Evanda",
      "HHR",
      "Kalos",
      "Lacetti",
      "Lacetti SW",
      "Lumina",
      "Malibu",
      "Matiz",
      "Monte Carlo",
      "Nubira",
      "Orlando",
      "Spark",
      "Suburban",
      "Tacuma",
      "Tahoe",
      "Trax",
    ],
  },
  {
    brand: "Porsche",
    models: [
      "911 Carrera",
      "911 Carrera Cabrio",
      "911 Targa",
      "911 Turbo",
      "924",
      "944",
      "997",
      "Boxster",
      "Cayenne",
      "Cayman",
      "Macan",
      "Panamera",
    ],
  },
  {
    brand: "Honda",
    models: [
      "Accord",
      "Accord Coupé",
      "Accord Tourer",
      "City",
      "Civic",
      "Civic Aerodeck",
      "Civic Coupé",
      "Civic Tourer",
      "Civic Type R",
      "CR-V",
      "CR-X",
      "CR-Z",
      "FR-V",
      "HR-V",
      "Insight",
      "Integra",
      "Jazz",
      "Legend",
      "Prelude",
      "Pilot",
    ],
  },
  {
    brand: "Subaru",
    models: [
      "BRZ",
      "Forester",
      "Impreza",
      "Impreza Wagon",
      "Justy",
      "Legacy",
      "Legacy Wagon",
      "Legacy Outback",
      "Levorg",
      "Outback",
      "SVX",
      "Tribeca",
      "Tribeca B9",
      "XV",
    ],
  },
  {
    brand: "Mazda",
    models: [
      "121",
      "2",
      "3",
      "323",
      "323 Combi",
      "323 Coupé",
      "323 F",
      "5",
      "6",
      "6 Combi",
      "626",
      "626 Combi",
      "B-Fighter",
      "B2500",
      "BT",
      "CX-3",
      "CX-5",
      "CX-7",
      "CX-9",
      "Demio",
      "MPV",
      "MX-3",
      "MX-5",
      "MX-6",
      "Premacy",
      "RX-7",
      "RX-8",
      "Xedox 6",
    ],
  },
  {
    brand: "Mitsubishi",
    models: [
      "3000 GT",
      "ASX",
      "Carisma",
      "Colt",
      "Colt CC",
      "Eclipse",
      "Fuso canter",
      "Galant",
      "Galant Combi",
      "Grandis",
      "L200",
      "L200 Pick up",
      "L200 Pick up Allrad",
      "L300",
      "Lancer",
      "Lancer Combi",
      "Lancer Evo",
      "Lancer Sportback",
      "Outlander",
      "Pajero",
      "Pajeto Pinin",
      "Pajero Pinin Wagon",
      "Pajero Sport",
      "Pajero Wagon",
      "Space Star",
    ],
  },
  {
    brand: "Lexus",
    models: [
      "CT",
      "GS",
      "GS 300",
      "GX",
      "IS",
      "IS 200",
      "IS 250 C",
      "IS-F",
      "LS",
      "LX",
      "NX",
      "RC F",
      "RX",
      "RX 300",
      "RX 400h",
      "RX 450h",
      "SC 430",
    ],
  },

  {
    brand: "BMW",
    models: [
      "i3",
      "i8",
      "M3",
      "M4",
      "M5",
      "M6",
      "Rad 1",
      "Rad 1 Cabrio",
      "Rad 1 Coupé",
      "Rad 2",
      "Rad 2 Active Tourer",
      "Rad 2 Coupé",
      "Rad 2 Gran Tourer",
      "Rad 3",
      "Rad 3 Cabrio",
      "Rad 3 Compact",
      "Rad 3 Coupé",
      "Rad 3 GT",
      "Rad 3 Touring",
      "Rad 4",
      "Rad 4 Cabrio",
      "Rad 4 Gran Coupé",
      "Rad 5",
      "Rad 5 GT",
      "Rad 5 Touring",
      "Rad 6",
      "Rad 6 Cabrio",
      "Rad 6 Coupé",
      "Rad 6 Gran Coupé",
      "Rad 7",
      "Rad 8 Coupé",
      "X1",
      "X3",
      "X4",
      "X5",
      "X6",
      "Z3",
      "Z3 Coupé",
      "Z3 Roadster",
      "Z4",
      "Z4 Roadster",
    ],
  },
  {
    brand: "Volkswagen",
    models: [
      "Amarok",
      "Beetle",
      "Bora",
      "Bora Variant",
      "Caddy",
      "Caddy Van",
      "Corrado",
      "Crafter",
      "Crafter Van",
      "Crafter Kombi",
      "CrossTouran",
      "Eos",
      "Fox",
      "Golf",
      "Golf Cabrio",
      "Golf Plus",
      "Golf Sportvan",
      "Golf Variant",
      "Jetta",
      "LT",
      "Lupo",
      "Multivan",
      "New Beetle",
      "New Beetle Cabrio",
      "Passat",
      "Passat Alltrack",
      "Passat CC",
      "Passat Variant",
      "Phaeton",
      "Polo",
      "Polo Van",
      "Scirocco",
      "Sharan",
      "T4",
      "T4 Caravelle",
      "T4 Multivan",
      "T5",
      "T5 Caravelle",
      "T5 Multivan",
      "T5 Transporter Shuttle",
      "Tiguan",
      "Touareg",
      "Touran",
      "up!",
      "Vento",
    ],
  },
  {
    brand: "Audi",
    models: [
      "100",
      "100 Avant",
      "80",
      "80 Avant",
      "80 Cabrio",
      "90",
      "A1",
      "A2",
      "A3",
      "A3 Cabriolet",
      "A3 Limuzina",
      "A3 Sportback",
      "A4",
      "A4 Allroad",
      "A4 Avant",
      "A5",
      "A5 Cabriolet",
      "A5 Sportback",
      "A6",
      "A6 Allroad",
      "A6 Avant",
      "A7",
      "A8",
      "Q3",
      "Q5",
      "Q7",
      "R8",
      "RS Q3",
      "RS3",
      "RS4 Avant",
      "RS5",
      "RS6 Avant",
      "RS7",
      "S3",
      "S5 Cabriolet",
      "S5 Coupé",
      "S5 Sportback",
      "S6",
      "S6 Avant",
      "S7",
      "S8",
      "SQ5",
      "TT Coupé",
      "TT Roadster",
      "TTS",
    ],
  },
  {
    brand: "Kia",
    models: [
      "Avella",
      "Carens",
      "Carnival",
      "Cee`d",
      "Cee`d SW",
      "Cerato",
      "Joice",
      "Magentis",
      "Opirus",
      "Optima",
      "Picanto",
      "Pregio",
      "Pride",
      "Pro Cee`d",
      "Rio",
      "Rio Combi",
      "Sephia",
      "Shuma",
      "Sorento",
      "Soul",
      "Sportage",
      "Venga",
    ],
  },
  {
    brand: "Mercedes-Benz",
    models: [
      "100 D",
      "115",
      "124",
      "126",
      "190",
      "190 D",
      "190 E",
      "200 - 300",
      "200 D",
      "200 E",
      "210 Van",
      "310 Van",
      "400",
      "500",
      "500 E",
      "A",
      "A L",
      "AMG GT",
      "B",
      "C",
      "C Sportcoupé",
      "C T",
      "CL",
      "CLA",
      "CLC",
      "CLK",
      "CLS",
      "E",
      "E Cabrio",
      "E Coupé",
      "E T",
      "G Cabrio",
      "G Klasse",
      "GL",
      "GLA",
      "GLC",
      "GLE",
      "GLK",
      "S",
      "S Coupé",
      "SL",
      "SLC",
      "SLK",
      "V",
      "Viano",
      "Vito",
      "Vito Mixto",
    ],
  },
  {
    brand: "Saab",
    models: [
      "9-3",
      "9-3 Cabriolet",
      "9-3 Coupé",
      "9-3 SportCombi",
      "9-5",
      "9-5 SportCombi",
      "900",
      "900 C",
      "9000",
    ],
  },
  {
    brand: "Hyundai",
    models: [
      "Accent",
      "Atos",
      "Atos Prime",
      "Coupé",
      "Elantra",
      "Galloper",
      "Genesis",
      "Getz",
      "Grandeur",
      "H 350",
      "H1",
      "H1 Bus",
      "H1 Van",
      "H200",
      "i10",
      "i20",
      "i30",
      "i30 CW",
      "i40",
      "i40 CW",
      "ix20",
      "ix35",
      "ix55",
      "Lantra",
      "Matrix",
      "Pony",
      "Santa Fe",
      "Sonata",
      "Terracan",
      "Trajet",
      "Tucson",
      "Veloster",
    ],
  },
  {
    brand: "Nissan",
    models: [
      "100 NX",
      "200 SX",
      "350 Z",
      "370 Z",
      "Almera",
      "Almera Tino",
      "Cabstar E",
      "e-NV200",
      "GT-R",
      "Insterstar",
      "Juke",
      "King Cab",
      "Leaf",
      "Maxima",
      "Maxima QX",
      "Micra",
      "Murano",
      "Navara",
      "Note",
      "NP300 Pickup",
      "NV200",
      "NV400",
      "Pathfinder",
      "Patrol",
      "Patrol GR",
      "Pickup",
      "Pixo",
      "Primastar",
      "Primastar Combi",
      "Primera",
      "Primera Combi",
      "Pulsar",
      "Qashqai",
      "Serena",
      "Sunny",
      "Terrano",
      "Tiida",
      "Trade",
      "Vanette Cargo",
      "X-Trail",
    ],
  },
  {
    brand: "Volvo",
    models: [
      "240",
      "340",
      "360",
      "460",
      "850",
      "850 kombi",
      "C30",
      "C70",
      "C70 Cabrio",
      "C70 Coupé",
      "S40",
      "S60",
      "S70",
      "S80",
      "S90",
      "V40",
      "V50",
      "V60",
      "V70",
      "V90",
      "XC60",
      "XC70",
      "XC90",
    ],
  },
  {
    brand: "Suzuki",
    models: [
      "Alto",
      "Baleno",
      "Baleno kombi",
      "Celerio",
      "Grand Vitara",
      "Grand Vitara XL-7",
      "Ignis",
      "Jimny",
      "Kizashi",
      "Liana",
      "Samurai",
      "Splash",
      "Swift",
      "SX4",
      "SX4 Sedan",
      "Vitara",
      "Wagon R+",
      "XL-7",
    ],
  },
  {
    brand: "Ford",
    models: [
      "B-MAX",
      "C-MAX",
      "Cortina",
      "Cougar",
      "Edge",
      "Escort",
      "Escort Cabrio",
      "Escort kombi",
      "Explorer",
      "F-150",
      "F-250",
      "Focus",
      "Focus Cabrio",
      "Focus C-MAX",
      "Focus kombi",
      "Fusion",
      "Galaxy",
      "Grand C-MAX",
      "Ka",
      "Kuga",
      "Maverick",
      "Mondeo",
      "Mondeo kombi",
      "Mustang",
      "Orion",
      "Puma",
      "Ranger",
      "S-MAX",
      "Sierra",
      "Street Ka",
      "Tourneo Connect",
      "Tourneo Custom",
      "Transit",
      "Transit",
      "Transit Connect LWB",
      "Transit Courier",
      "Transit Custom",
      "Transit kombi",
      "Transit Tourneo",
      "Transit Valnik",
      "Transit Van",
      "Transit Van 350",
      "Windstar",
    ],
  },
  {
    brand: "Fiat",
    models: [
      "1100",
      "126",
      "500",
      "500L",
      "500X",
      "850",
      "Barchetta",
      "Brava",
      "Cinquecento",
      "Coupé",
      "Croma",
      "Doblo",
      "Doblo Cargo",
      "Ducato",
      "Ducato Van",
      "Fiorino",
      "Freemont",
      "Fullback",
      "Idea",
      "Linea",
      "Marea",
      "Marea Weekend",
      "Multipla",
      "Palio",
      "Panda",
      "Panda Van",
      "Punto",
      "Punto Cabrio",
      "Punto Evo",
      "Punto Van",
      "Qubo",
      "Scudo",
      "Scudo Van",
      "Sedici",
      "Seicento",
      "Stilo",
      "Stilo Multiwagon",
      "Strada",
      "Talento",
      "Tipo",
      "Ulysse",
      "Uno",
      "X1/9",
    ],
  },
  {
    brand: "Land Rover",
    models: [
      "109",
      "Defender",
      "Discovery",
      "Discovery Sport",
      "Freelander",
      "Range Rover",
      "Range Rover Evoque",
      "Range Rover Sport",
      "Range Rover Velar",
    ],
  },
  {
    brand: "Jaguar",
    models: [
      "Daimler",
      "F-Pace",
      "F-Type",
      "S-Type",
      "X-Type",
      "XE",
      "XF",
      "XJ",
      "XK",
      "XKR",
    ],
  },
  {
    brand: "Subaru",
    models: [
      "BRZ",
      "Forester",
      "Impreza",
      "Impreza Wagon",
      "Justy",
      "Legacy",
      "Legacy Wagon",
      "Levorg",
      "Outback",
      "SVX",
      "Tribeca",
      "Tribeca B9",
      "WRX",
      "XV",
    ],
  },
  {
    brand: "Mazda",
    models: [
      "121",
      "2",
      "3",
      "323",
      "323 Coupé",
      "323F",
      "5",
      "6",
      "6 kombi",
      "626",
      "B-Fighter",
      "B2500",
      "CX-3",
      "CX-5",
      "CX-7",
      "CX-9",
      "MPV",
      "MX-3",
      "MX-5",
      "MX-6",
      "Premacy",
      "RX-7",
      "RX-8",
      "Xedox 6",
    ],
  },
  {
    brand: "Mitsubishi",
    models: [
      "3000 GT",
      "ASX",
      "Carisma",
      "Colt",
      "Colt CZC",
      "Colt kombi",
      "Eclipse",
      "FTO",
      "Galant",
      "Galant kombi",
      "Grandis",
      "L200",
      "L200 Pick up",
      "L300",
      "Lancer",
      "Lancer Combi",
      "Lancer Evo",
      "Lancer Sportback",
      "Outlander",
      "Pajero",
      "Pajeto Pinin",
      "Pajero Pinin Wagon",
      "Pajero Sport",
      "Pajero Wagon",
      "Space Star",
    ],
  },
  {
    brand: "Honda",
    models: [
      "Accord",
      "Accord Coupé",
      "Accord Tourer",
      "City",
      "Civic",
      "Civic Aerodeck",
      "Civic Coupé",
      "Civic Tourer",
      "Concerto",
      "CR-V",
      "CR-X",
      "CR-Z",
      "Element",
      "FR-V",
      "HR-V",
      "Insight",
      "Integra",
      "Jazz",
      "Legend",
      "Prelude",
    ],
  },
  {
    brand: "Alfa Romeo",
    models: [
      "145",
      "146",
      "147",
      "155",
      "156",
      "156 Sportwagon",
      "159",
      "159 Sportwagon",
      "164",
      "166",
      "4C",
      "Brera",
      "GTV",
      "MiTo",
      "Spider",
      "GT",
      "Giulia",
      "Giulietta",
    ],
  },
  {
    brand: "Chrysler",
    models: [
      "300 C",
      "300 C Touring",
      "Crossfire",
      "Grand Voyager",
      "LHS",
      "Neon",
      "Pacifica",
      "Plymouth",
      "PT Cruiser",
      "Sebring",
      "Sebring Convertible",
      "Stratus",
      "Stratus Cabrio",
      "Vision",
      "Viper",
      "Voyager",
    ],
  },
  {
    brand: "Jeep",
    models: [
      "Cherokee",
      "Commander",
      "Compass",
      "Grand Cherokee",
      "Patriot",
      "Renegade",
      "Wrangler",
    ],
  },
  {
    brand: "Chevrolet",
    models: [
      "Alero",
      "Aveo",
      "Camaro",
      "Captiva",
      "Corvette",
      "Cruze",
      "Cruze SW",
      "Epica",
      "Equinox",
      "Evanda",
      "HHR",
      "Kalos",
      "Lacetti",
      "Lacetti SW",
      "Lumina",
      "Malibu",
      "Matiz",
      "Monte Carlo",
      "Nubira",
      "Nubira SW",
      "Orlando",
      "Spark",
      "Suburban",
      "Tacuma",
      "Tahoe",
      "Trax",
    ],
  },
  {
    brand: "Dacia",
    models: [
      "Dokker",
      "Dokker Van",
      "Duster",
      "Lodgy",
      "Logan",
      "Logan MCV",
      "Logan Van",
      "Sandero",
      "Solenza",
    ],
  },
  {
    brand: "Renault",
    models: [
      "Avantime",
      "Captur",
      "Clio",
      "Clio Grandtour",
      "Clio Storia",
      "Espace",
      "Fluence",
      "Grand Espace",
      "Grand Modus",
      "Grand Scenic",
      "Kadjar",
      "Kangoo",
      "Kangoo Express",
      "Koleos",
      "Laguna",
      "Laguna Grandtour",
      "Latitude",
      "Mascott",
      "Mégane",
      "Mégane CC",
      "Mégane Combi",
      "Mégane Grandtour",
      "Mégane coupé",
      "Mégane Scénic",
      "Scénic",
      "Talisman",
      "Talisman Grandtour",
      "Thalia",
      "Twingo",
      "Vel Satis",
      "Wind",
    ],
  },
  {
    brand: "Peugeot",
    models: [
      "1007",
      "106",
      "107",
      "108",
      "2008",
      "205",
      "205 Cabrio",
      "206",
      "206 CC",
      "206 SW",
      "207",
      "207 CC",
      "207 SW",
      "208",
      "3008",
      "306",
      "306 Cabrio",
      "307",
      "307 CC",
      "307 SW",
      "308",
      "308 CC",
      "308 SW",
      "309",
      "4007",
      "4008",
      "405",
      "406",
      "406 Coupé",
      "407",
      "407 SW",
      "5008",
      "508",
      "508 SW",
      "605",
      "806",
      "807",
      "Bipper",
      "Bipper Tepee",
      "Boxer",
      "Boxer Combi",
      "Expert",
      "Expert Tepee",
      "iOn",
      "Partner",
      "Partner Tepee",
      "RCZ",
    ],
  },
  {
    brand: "Citroën",
    models: [
      "Berlingo",
      "Berlingo First",
      "BX",
      "C-Crosser",
      "C-Elissée",
      "C1",
      "C2",
      "C3",
      "C3 Picasso",
      "C4",
      "C4 Aircross",
      "C4 Cactus",
      "C4 Coupé",
      "C4 Grand Picasso",
      "C4 Sedan",
      "C5",
      "C5 Break",
      "C5 Tourer",
      "C6",
      "C8",
      "DS3",
      "DS4",
      "DS5",
      "Evasion",
      "Jumper",
      "Jumpy",
      "Saxo",
      "Nemo",
      "Nemo Combi",
      "Xantia",
      "XM",
      "Xsara",
      "Xsara Picasso",
      "ZX",
    ],
  },
  {
    brand: "Toyota",
    models: [
      "4-Runner",
      "Auris",
      "Avensis",
      "Avensis Verso",
      "Aygo",
      "Camry",
      "Carina",
      "Celica",
      "Corolla",
      "Corolla Verso",
      "FJ Cruiser",
      "GT86",
      "Hiace",
      "Hiace Van",
      "Hilux",
      "Land Cruiser",
      "MR2",
      "Paseo",
      "Picnic",
      "Prius",
      "RAV4",
      "Sienna",
      "Sequoia",
      "Starlet",
      "Supra",
      "Tundra",
      "Urban Cruiser",
      "Yaris",
      "Yaris Verso",
      "Venza",
      "Avalon",
      "C-HR",
      "Corona",
      "Crown",
      "Echo",
      "Estima",
      "Fortuner",
      "Harrier",
      "Highlander",
      "Ipsum",
      "Matrix",
      "Previa",
      "Proace",
      "Rush",
      "Tacoma",
      "TownAce",
      "Verso",
      "Vitz",
      "Voxy",
      "Windom",
      "Wish",
    ],
  },

  {
    brand: "Skoda",
    models: [
      "Favorit",
      "Felicia",
      "Felicia Combi",
      "Citigo",
      "Fabia",
      "Fabia Combi",
      "Fabia Sedan",
      "Kamiq",
      "Karoq",
      "Kodiaq",
      "Octavia",
      "Octavia Combi",
      "Roomster",
      "Rapid",
      "Rapid Spaceback",
      "Scala",
      "Superb",
      "Superb Combi",
      "Yeti",
    ],
  },
  {
    brand: "Seat",
    models: [
      "Alhambra",
      "Altea",
      "Altea XL",
      "Arosa",
      "Cordoba",
      "Cordoba Vario",
      "Exeo",
      "Ibiza",
      "Ibiza ST",
      "Inca",
      "Leon",
      "Leon ST",
      "Marbella",
      "Mii",
      "Toledo",
    ],
  },
  {
    brand: "Rover",
    models: ["200", "214", "218", "25", "400", "414", "416", "620", "75"],
  },
  {
    brand: "Mini",
    models: [
      "Cooper",
      "Cooper Cabrio",
      "Cooper D",
      "Cooper D Clubman",
      "Cooper S",
      "Cooper S Clubman",
      "Countryman",
      "Mini One",
      "One D",
    ],
  },
  {
    brand: "Porsche",
    models: [
      "911 Carrera",
      "911 Carrera Cabrio",
      "911 Targa",
      "911 Turbo",
      "924",
      "944",
      "997",
      "Boxster",
      "Cayenne",
      "Cayman",
      "Macan",
      "Panamera",
    ],
  },
  {
    brand: "Tesla",
    models: ["Model S", "Model X"],
  },
  {
    brand: "Smart",
    models: [
      "Cabrio",
      "City-Coupé",
      "Compact Pulse",
      "Forfour",
      "Fortwo",
      "Roadster",
    ],
  },
  {
    brand: "Lancia",
    models: [
      "Dedra",
      "Lybra",
      "Phedra",
      "Prisma",
      "Thema",
      "Thesis",
      "Voyager",
      "Y",
      "Ypsilon",
      "Zeta",
    ],
  },
  {
    brand: "Daewoo",
    models: [
      "Espero",
      "Kalos",
      "Lacetti",
      "Lanos",
      "Leganza",
      "Lublin",
      "Matiz",
      "Nexia",
      "Nubira",
      "Nubira kombi",
      "Racer",
      "Tacuma",
      "Tico",
    ],
  },
  {
    brand: "Trabant",
    models: ["601"],
  },
  {
    brand: "Wartburg",
    models: ["353"],
  },
  {
    brand: "Moskwicz",
    models: ["408", "412", "Aleko"],
  },
  {
    brand: "Volga",
    models: ["GAZ 24"],
  },
  {
    brand: "Zastava",
    models: ["Yugo"],
  },
  {
    brand: "Alpina",
    models: ["B3", "B5", "B6", "D3"],
  },
  {
    brand: "Aston Martin",
    models: [
      "DB7",
      "DB9",
      "DBS",
      "One-77",
      "Rapide",
      "V12 Vanquish",
      "V8 Vantage",
      "Virage",
    ],
  },
  {
    brand: "Bentley",
    models: [
      "Arnage",
      "Azure",
      "Bentayga",
      "Brooklands",
      "Continental",
      "Continental Flying Spur",
      "Continental GT",
      "Continental GTC",
      "Eight",
      "Mulsanne",
      "Turbo R",
    ],
  },
  {
    brand: "Cadillac",
    models: [
      "Allante",
      "ATS",
      "BLS",
      "CTS",
      "CTS Sport",
      "CTS-V",
      "Deville",
      "Eldorado",
      "Fleetwood",
      "Seville",
      "Seville STS",
    ],
  },
  {
    brand: "Dodge",
    models: [
      "Avenger",
      "Caliber",
      "Challenger",
      "Charger",
      "Journey",
      "Magnum",
      "Nitro",
      "RAM",
    ],
  },
  {
    brand: "Ferrari",
    models: [
      "458",
      "488",
      "612 Scaglietti",
      "California",
      "Enzo",
      "F12",
      "F355",
      "F430",
      "F50",
      "FF",
    ],
  },
  {
    brand: "Lamborghini",
    models: ["Aventador", "Diablo", "Gallardo", "Huracán", "Murciélago"],
  },
  {
    brand: "Lada",
    models: [
      "111",
      "112",
      "1200",
      "2101",
      "2104",
      "2105",
      "2107",
      "2110",
      "Niva",
    ],
  },
  {
    brand: "Maserati",
    models: ["3200 GT", "4200 GT", "Ghibli", "GranTurismo", "Quattroporte"],
  },
  {
    brand: "Rolls-Royce",
    models: [
      "Corniche",
      "Ghost",
      "Park Ward",
      "Phantom",
      "Silver Seraph",
      "Silver Spur",
      "Wraith",
    ],
  },
  {
    brand: "Acura",
    models: [
      "CL",
      "ILX",
      "Integra",
      "Legend",
      "MDX",
      "NSX",
      "RDX",
      "RL",
      "RLX",
      "RSX",
      "TL",
      "TLX",
      "TSX",
      "ZDX",
    ],
  },
  {
    brand: "Innoson (IVM)",
    models: ["Umu Sedan", "Umu SUV", "G5T", "G6", "G80", "G80T", "B60", "G5"],
  },
  {
    brand: "NORD",
    models: ["A3", "Flit", "Max", "Tank", "Trucks"],
  },
  {
    brand: "Geely",
    models: [
      "Atlas",
      "Boyue",
      "Emgrand",
      "Emgrand GT",
      "Emgrand X7",
      "GC6",
      "MK",
    ],
  },

  {
    brand: "MG",
    models: ["MG3", "MG5", "MG6", "MG7", "MG GS", "MG HS", "MG ZS"],
  },
  {
    brand: "Isuzu",
    models: [
      "Ascender",
      "Axiom",
      "D-Max",
      "Hombre",
      "i-Series",
      "MU-X",
      "Rodeo",
      "Trooper",
      "VehiCROSS",
    ],
  },
  {
    brand: "RAM",
    models: [
      "1500",
      "2500",
      "3500",
      "Chassis Cab",
      "ProMaster",
      "ProMaster City",
    ],
  },
  {
    brand: "Buick",
    models: [
      "Cascada",
      "Enclave",
      "Encore",
      "Encore GX",
      "Envision",
      "LaCrosse",
      "Regal",
      "Verano",
    ],
  },
  {
    brand: "GMC",
    models: [
      "Acadia",
      "Canyon",
      "Sierra 1500",
      "Sierra 2500HD",
      "Sierra 3500HD",
      "Terrain",
      "Yukon",
    ],
  },
  {
    brand: "Lincoln",
    models: [
      "Aviator",
      "Continental",
      "Corsair",
      "MKC",
      "MKT",
      "MKX",
      "MKZ",
      "Nautilus",
      "Navigator",
    ],
  },
  {
    brand: "Mercury",
    models: [
      "Cougar",
      "Grand Marquis",
      "Marauder",
      "Mariner",
      "Milan",
      "Montego",
      "Monterey",
      "Mountaineer",
      "Sable",
    ],
  },
  {
    brand: "Mahindra",
    models: [
      "Alturas G4",
      "Bolero",
      "KUV100",
      "Marazzo",
      "Scorpio",
      "Thar",
      "XUV300",
      "XUV500",
      "XUV700",
    ],
  },
  {
    brand: "Saturn",
    models: [
      "Astra",
      "Aura",
      "Ion",
      "L-Series",
      "Outlook",
      "Relay",
      "Sky",
      "S-Series",
      "Vue",
    ],
  },
];

export default cars;
