import React, { useState, useEffect } from "react";
import styles from "../forgotPassword/ForgotPassword.module.css";
import Logo from "../../Components/interface/Logo";
import Button from "../../Components/interface/Button";
import { useNavigate } from "react-router";
import cars from "../../utils/CarList";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Authentication from "../../utils/api/Authentication";
import Insurance from "../../utils/api/Insurance";
import Notifications from "../../utils/api/Notifications";
import { dongleActions } from "../../store/redux-store/dongle-store";

function RegisterVehicleForm(props) {
  const [loading, setLoading] = useState(false);
  const [selectInput, setSelectInput] = useState("");
  const [selectInput2, setSelectInput2] = useState("");
  const [selectInput3, setSelectInput3] = useState("");
  const [selectInput4, setSelectInput4] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const list = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      selectInput.trim().length >= 1 &&
      selectInput2.trim().length >= 1 &&
      selectInput4.trim().length >= 1 &&
      selectInput3.trim().length >= 1
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [selectInput, selectInput2, selectInput4, selectInput3]);

  const model = cars?.find((model, index) => model.brand === selectInput2);

  function generateRandomId(length) {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  let submitHandler = async (e) => {
    e.preventDefault();
    const generated_id = generateRandomId(12);

    dispatch(dongleActions.changeVehicle(0));

    try {
      setLoading(true);
      setFormIsValid(false);

      // Handle early return for player ID registration
      if (props.parameter) {
        const postPlayerId = await Insurance.postPlayerId({
          phone_no: list.personalInfo?.number,
          player_id: props.parameter,
        });
        return postPlayerId.data;
      }

      // Create array of promises to execute in parallel
      const promises = [
        Authentication.signupHandler({
          email: list.personalInfo?.email,
          password: list.personalInfo?.password,
          fullname: list.personalInfo?.name,
          phone: list.personalInfo?.number,
          vehicle_data: {
            plate_number: list.plateNumber,
            vehicle_year: selectInput,
            vehicle_make: selectInput2,
            vehicle_model: selectInput3,
            vehicle_trim: "44444",
          },
        }),
        Authentication.regCollectorHandler({
          email: list.personalInfo?.email,
          fullname: list.personalInfo?.name,
          phone_no: list.personalInfo?.number,
          plate_no: list.plateNumber,
        }),
        Authentication.setengineHandler({
          list: list.plateNumber,
          selectInput2: selectInput2,
          selectInput3: selectInput3,
          selectInput: selectInput,
          selectInput4: selectInput4,
        }),
        Authentication.premiumPapersHandler({
          email: list.personalInfo?.email,
          name: list.personalInfo?.name,
          phone_no: list.personalInfo?.number,
          plate_no: list.plateNumber,
        }),
        // Authentication.registerUserToPremiumPapers({
        //   email: list.personalInfo?.email,
        //   name: list.personalInfo?.name,
        //   phone_no: list.personalInfo?.number,
        //   plate_no: list.plateNumber,
        // }),
        Authentication.brevoWelcomeMessage({
          email: list.personalInfo?.email,
          name: list.personalInfo?.name,
        }),
        Authentication.addContactToBrevo({
          email: list.personalInfo?.email,
          name: list.personalInfo?.name,
          id: generated_id,
        }),
      ];

      await Promise.all(promises)
        .then(async () => {
          toast.success(
            "Registration Completed Successfully. You will be redirected soon.",
            {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );

          // Handle progressier integration
          const progressier = window.progressier;
          if (progressier) {
            progressier.add({
              id: list.plateNumber.toLowerCase(),
            });

            await Notifications.sendNotification({
              plate_number: list.plateNumber.toLowerCase(),
              title: `Welcome to Motormata ${list.personalInfo?.name}`,
              desc: `We're thrilled to have you on board. Get ready to streamline your journey with our comprehensive features designed to enhance every aspect of your driving experience. From navigation to maintenance reminders, we've got you covered. Let's hit the road together.`,
            });
          }

          setLoading(false);
          setFormIsValid(true);
          navigate("/login");
        })
        .catch((error) => {
          if (error?.response?.data === "Plate number has been taken") {
            toast.success(
              "Registration Completed Successfully. You will be redirected soon.",
              {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
            navigate("/login");
            return;
          }

          console.error("Registration error:", error?.response);
          toast.error(error?.response?.data?.errors, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    } catch (error) {
      if (error?.response?.data === "Plate number has been taken") {
        toast.success(
          "Registration Completed Successfully. You will be redirected soon.",
          {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        navigate("/login");
        return;
      }
      setLoading(false);
      setFormIsValid(true);
      console.log(error.response);
      toast.error(
        error.response?.data?.errors?.message ||
          error.response?.data?.errors?.email ||
          error?.response?.data,
        {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };
  let selectChangeHandler = (e) => {
    setSelectInput(e.target.value);
  };
  let brandSelectChangeHandler = (e) => {
    setSelectInput2(e.target.value);
  };
  let modelSelectChangeHandler = (e) => {
    setSelectInput3(e.target.value);
  };
  let vehicleTypeHandler = (e) => {
    setSelectInput4(e.target.value);
  };

  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Register</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Brand</label>
            <div className={styles.form__passwordInput}>
              <select onChange={brandSelectChangeHandler}>
                <option value="">Brand</option>
                {cars
                  .sort((a, b) => a.brand.localeCompare(b.brand))
                  .map((data, index) => (
                    <option key={data.brand} value={data.brand}>
                      {data.brand}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Model</label>
            <div className={styles.form__passwordInput}>
              <select onChange={modelSelectChangeHandler}>
                <option value="">Choose Model</option>
                {model?.models.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Year</label>
            <div className={styles.form__passwordInput}>
              <select onChange={selectChangeHandler}>
                <option value="">Choose Year</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </div>
          </div>
          <div className={styles.form__inner}>
            <label htmlFor="Year">Vehicle Type</label>
            <div className={styles.form__passwordInput}>
              <select onChange={vehicleTypeHandler}>
                <option value="Vehicle Type">Vehicle Type</option>
                <option value="SUV">SUV</option>
                <option value="Hatchback">Hatchback</option>
                <option value="Crossover">Crossover</option>
                <option value="Convertible">Convertible</option>
                <option value="Sedan">Sedan</option>
                <option value="Sports Car">Sports Car</option>
                <option value="Coupe">Coupe</option>
                <option value="Minivan">Minivan</option>
                <option value="Station Wagon">Station Wagon</option>
                <option value="Pickup Truck">Pickup Truck</option>
              </select>
            </div>
          </div>

          {!loading && <Button disabled={!formIsValid}>Continue</Button>}
          {loading && <Button disabled={!formIsValid}>Loading...</Button>}
        </form>
      </div>
    </section>
  );
}

export default RegisterVehicleForm;
